import React from "react";

export default function ExperienceImg(props) {

  const theme = props.theme;

  return (
    <svg 
    data-name="Layer 1" 
    width="666.74068" 
    height="729.25401" 
    viewBox="0 0 666.74068 729.25401" 
   >
    
    
    <path 
    d="M281.82442,290.10639c8.34434-5.7074,19.08371,6.871,13.24169,14.70226-5.90536,7.91619-18.8159,5.67215-23.2615-2.41079-5.67221-10.31318-.02369-22.58111,9.11759-28.56828,10.38416-6.80121,23.11071-7.18388,35.10093-6.54654,12.1106.64374,23.92867,1.49836,35.99369-.23774a120.73877,
    120.73877,0,0,0,29.69288-8.32752,126.144,126.144,0,0,0,49.8754-37.784c6.154-7.80767,11.90524-16.583,15.428-25.92979,2.71341-7.19943,3.9989-16.66759.15412-23.71471a12.07258,12.07258,0,0,0-18.35325-3.68663c-6.40752,5.24951-8.138,14.99707-3.23891,21.78944,5.257,7.28855,15.177,
    9.78691,23.71776,8.45986,11.86991-1.84433,21.482-10.22422,30.56922-17.43882l67.2996-53.43089c11.0974-8.81052,22.0813-17.79181,33.32164-26.41918,9.1408-7.01591,20.295-13.81866,32.20023-10.07127,4.799,1.51059,9.63563,5.08793,10.83522,10.22752a13.16117,13.16117,0,0,1-4.7193,
    12.74974,8.09512,8.09512,0,0,1-11.84143-1.48468c-2.50885-3.49919-2.71523-8.4881-1.27026-12.442,3.86174-10.56686,18.03216-11.6724,27.48815-10.813A77.22567,77.22567,0,0,1,664.76,99.12489c19.98427,11.60732,34.76356,30.48943,43.43235,51.71619,9.60506,23.51938,12.56417,49.545,
    12.17205,74.79323-.08,5.14952-.43642,10.84562-3.335,15.28575-2.83881,4.34857-7.79157,5.66476-12.234,2.7567-1.44679-.94709-3.3066-2.79208-2.92943-4.73814.47352-2.44313,5.34266-2.46191,7.29318-2.36846,5.33751.25575,10.38375,2.94639,14.207,6.57385,9.0862,8.62086,9.79272,21.81147,
    8.608,33.54021-1.30056,12.87567-5.01169,25.68168-3.53791,38.71616,2.41448,21.35441,19.93636,33.22044,37.146,43.03732,8.8138,5.02765,17.82,9.83406,25.74561,16.23019a106.03985,106.03985,0,0,1,20.98748,22.84195,115.37637,115.37637,0,0,1,19.7023,57.1908,105.53268,105.53268,0,0,
    1-2.39888,30.5053c-2.26385,9.779-5.83027,22.1063-15.22608,27.35916a12.60725,12.60725,0,0,1-14.367-1.28945c-3.80691-3.05515-5.66724-8.47827-1.73467-12.29542,3.25844-3.16281,8.47294-2.49621,12.40448-1.21049,4.73362,1.548,8.41716,4.437,11.94539,7.84961,6.72557,6.50519,14.599,
    14.49134,24.83116,12.41372,1.89062-.38389,1.09042-3.27619-.79752-2.89284-12.35239,2.50814-19.79963-11.84817-28.909-17.37541-6.595-4.00161-17.85952-6.97683-23.01329.82711-5.56179,8.42178,3.3807,17.50844,11.69093,18.593,10.80476,1.41014,18.29518-9.15447,21.92294-17.8746,
    8.41365-20.22408,8.67488-43.93815,3.52854-64.9969a115.92853,115.92853,0,0,0-32.07-56.16929c-7.97612-7.60237-17.11993-13.43026-26.65932-18.85516-9.62956-5.47618-19.66531-10.72962-27.90441-18.24566a41.83967,41.83967,0,0,1-14.1556-32.26254c.08835-14.01189,4.593-27.60908,
    4.31223-41.648-.2328-11.6399-4.18837-23.006-14.3143-29.55917a23.14517,23.14517,0,0,0-14.19069-3.96115c-2.0484.16831-4.92556.51332-6.61288,1.79567-1.35878,1.03267-1.61612,3.45008-1.34211,5.01288.65083,3.71194,4.16155,6.2899,7.57172,7.323a10.842,10.842,0,0,0,11.7083-3.45511c3.27936-3.787,
    4.41788-8.83757,4.882-13.7006.62878-6.58858.28111-13.35648-.00931-19.95592-1.18026-26.82-6.48-54.28185-20.41812-77.57483-11.84784-19.79977-30.274-36.4015-52.36766-43.74632-10.41108-3.461-22.76106-5.53518-33.50356-2.38746-9.2426,2.70823-16.32493,10.31509-14.79882,20.38988,
    1.29186,8.52836,10.12862,14.42716,17.91578,9.0443a15.52309,15.52309,0,0,0,2.48681-23.39606c-7.528-7.88347-19.80311-7.82673-29.19644-3.8203-5.65669,2.41269-10.66482,6.02758-15.49509,9.7829-6.15714,4.78689-12.22764,9.68846-18.33568,14.53779l-73.58089,58.41778c-5.80755,
    4.61076-11.52162,9.38992-17.523,13.74823-5.60021,4.067-11.77867,7.83407-18.67267,9.13986-9.708,1.83879-25.07571-2.9835-23.89836-15.29672.45721-4.78174,3.62453-10.06349,8.46585-11.43467,4.74568-1.34409,9.22607,1.70875,10.99028,6.03057,3.88931,9.52773-.67813,20.42644-5.221,
    28.79057-11.2483,20.70977-28.99144,37.57553-49.78092,48.54993a121.03528,121.03528,0,0,1-33.10862,11.74945c-12.97267,2.54876-25.702,2.05-38.8182,1.25443-12.71441-.77121-26.06564-.69235-37.398,5.9333-9.29227,5.4329-15.49112,15.28529-14.0016,26.26418,1.29348,9.53389,8.93982,
    16.8209,18.80155,16.01575,8.2775-.6758,16.59384-7.74444,13.657-16.72252-2.43191-7.43447-11.819-13.29727-18.99876-8.38644-1.584,1.0834-.085,3.68423,1.51416,2.59041Z" transform="translate(-266.62966 -85.37299)" fill="#3f3d56"/>
    
    <path 
    d="M917.58774,506.70207H844.90243a4.28809,4.28809,0,0,0-4.28325,4.28326v45.2535a4.28813,4.28813,0,0,0,4.28325,4.28326h72.68525a4.28811,4.28811,0,0,0,4.28331-4.28326v-45.2535A4.28805,4.28805,0,0,0,917.58774,506.70207Z" 
    transform="translate(-266.62966 -85.37299)" fill="#f2f2f2" />
    
    
    <path 
    d="M917.58774,506.70207H844.90243a4.28809,4.28809,0,0,0-4.28325,4.28326v45.2535a4.28813,4.28813,0,0,0,4.28325,4.28326h72.68525a4.28811,4.28811,0,0,0,4.28331-4.28326v-45.2535A4.28805,4.28805,0,0,0,917.58774,506.70207Zm1.40563,32.50616a17.608,17.608,0,0,1-17.31149,17.53912c-7.37524.096,
    4.08065.13344-4.81443.13344-20.34014,0-41.54928-.19437-49.46265-.32039a3.99033,3.99033,0,0,1-3.908-3.974V510.98868a1.41039,1.41039,0,0,1,1.40535-1.40882h72.68265a1.41023,1.41023,0,0,1,1.40858,1.40505Z" transform="translate(-266.62966 -85.37299)" fill="#e6e6e6"/>
    
    <path d="M908.64029,480.64368H853.26173a2.38009,2.38009,0,0,0-2.23738,1.57772l-8.55284,23.99548a2.37533,2.37533,0,0,0,2.234,3.17278l74.27173.13182h.00331a2.3753,2.3753,0,0,0,2.18316-3.31092l-.1295.05544.1295-.05544-10.3403-24.12729A2.3724,2.3724,0,0,0,908.64029,480.64368Z" 
    transform="translate(-266.62966 -85.37299)" fill="#e6e6e6"/>
    
    
    <path d="M921.356,506.73164a2.3816,2.3816,0,0,1-.19231.9359L910.82407,526.24a2.38187,2.38187,0,0,1-2.18374,1.438H853.26258a2.38175,2.38175,0,0,1-2.23929-1.57691l-8.55122-18.44a2.37377,2.37377,0,0,1,1.44017-3.03416,2.33692,2.33692,0,0,1,.79272-.1389l9.07258-.01708,3.703-.00642,
    48.72608-.08547,3.7051-.00641,9.06617-.01709H918.98A2.37644,2.37644,0,0,1,921.356,506.73164Z" transform="translate(-266.62966 -85.37299)" fill="#e6e6e6"/>
    
    <path d="M850.35149,540.64743c1.09814,0,16.17808.92161,16.17808,2.01975s-15.07994,1.957-16.17808,1.957a1.98836,1.98836,0,0,1,0-3.97672Z" transform="translate(-266.62966 -85.37299)" fill="#e6e6e6"/>
    
    <path d="M905.19164,466.04609H858.49758a4.72551,4.72551,0,0,0-4.72,4.72v56.91193h54.86279a2.34556,2.34556,0,0,0,1.27136-.36967V470.76613A4.72552,4.72552,0,0,0,905.19164,466.04609Z" transform="translate(-266.62966 -85.37299)" fill="#f2f2f2"/>
    
    <path d="M887.36915,469.873H861.77962a4.30331,4.30331,0,0,0-4.29911,4.297v53.5081h48.72608V488.71043A18.85921,18.85921,0,0,0,887.36915,469.873Z" transform="translate(-266.62966 -85.37299)" fill="#fff"/>
    
    <path d="M897.90538,522.926H865.78171a1.047,1.047,0,1,0,0,2.094h32.12367a1.047,1.047,0,1,0,0-2.094Z" transform="translate(-266.62966 -85.37299)" fill="#e4e4e4"/>
    
    <path d="M897.90538,518.785H865.78171a1.04807,1.04807,0,0,0,0,2.09614h32.12367a1.04807,1.04807,0,1,0,0-2.09614Z" transform="translate(-266.62966 -85.37299)" fill="#e4e4e4"/>
    
    <path d="M879.70681,514.64612h-13.9251a1.047,1.047,0,1,0,0,2.094h13.9251a1.047,1.047,0,1,0,0-2.094Z" transform="translate(-266.62966 -85.37299)" fill="#e4e4e4"/>
    
    <path d="M897.90538,505.97525H865.78171a1.04807,1.04807,0,0,0,0,2.09613h32.12367a1.04807,1.04807,0,1,0,0-2.09613Z" transform="translate(-266.62966 -85.37299)" fill="#e4e4e4"/>
    
    <path d="M897.90538,501.83639H865.78171a1.047,1.047,0,1,0,0,2.094h32.12367a1.047,1.047,0,1,0,0-2.094Z" transform="translate(-266.62966 -85.37299)" fill="#e4e4e4"/>
    
    <path d="M879.70681,497.6954h-13.9251a1.04807,1.04807,0,0,0,0,2.09614h13.9251a1.04807,1.04807,0,1,0,0-2.09614Z" transform="translate(-266.62966 -85.37299)" fill="#e4e4e4"/>
    
    <path d="M897.90538,489.02453H865.78171a1.04807,1.04807,0,0,0,0,2.09613h32.12367a1.04807,1.04807,0,1,0,0-2.09613Z" transform="translate(-266.62966 -85.37299)" fill="#e4e4e4"/>
    
    <path d="M897.90538,484.88568H865.78171a1.04807,1.04807,0,0,0,0,2.09613h32.12367a1.04807,1.04807,0,1,0,0-2.09613Z" transform="translate(-266.62966 -85.37299)" fill="#e4e4e4"/>
    
    <path d="M879.70681,480.74468h-13.9251a1.04807,1.04807,0,0,0,0,2.09614h13.9251a1.04807,1.04807,0,1,0,0-2.09614Z" transform="translate(-266.62966 -85.37299)" fill="#e4e4e4"/>
    
    <circle 
    cx="267.12389" 
    cy="168.12981" 
    r="30.9975" 
    fill={theme.skinColor}/>
    
    <path 
    d="M569.13749,235.7543c-3.26063-5.0444-9.3794-8.40634-15.31111-7.46163a20.90869,20.90869,0,0,0-35.0334-12.19548,6.50171,6.50171,0,0,0-6.35821-.63632,13.40617,13.40617,0,0,0-5.1656,4.14634,30.08788,30.08788,0,0,0-4.21015,30.60908c-.72494-2.6354,1.91618-5.11472,4.58474-5.7061,2.66833-.59139,5.46747-.03191,8.17354-.417,3.46828-.4935,6.586-2.5055,10.05975-2.9583,2.91943-.38054,5.85533.368,8.70226,1.11828,2.84694.75021,5.77529,
    1.51529,8.69884,1.16814,2.92377-.34715,6.68981,10.73278,6.63927,18.53676-.00961,1.47892.295,3.37772,1.74031,3.69148,1.77988.38648,2.81126-2.07008,4.50538-2.73945a3.13418,3.13418,0,0,1,3.84425,2.04424,5.10984,5.10984,0,0,1-.97559,4.57582,15.09938,15.09938,0,0,1-3.66083,3.17567l.69772.5803c1.1606,1.53324,3.568,1.59967,5.24085.65107a11.31116,11.31116,0,0,0,3.76283-4.28447c3.09483-5.14753,5.80959-10.63216,6.9018-16.53837S572.3979,240.7987,569.13749,235.7543Z" 
    transform="translate(-266.62966 -85.37299)" 
    fill={theme.dark}/>
    
    <polygon points="303.485 710.95 286.745 710.948 278.781 646.376 303.489 646.377 303.485 710.95" 
    fill={theme.skinColor}/>
    
    
    <path 
    d="M570.70466,813.78648l-51.48025-.00194v-.651A20.03865,20.03865,0,0,1,539.262,793.09626h.00124l9.40352-7.134,17.5449,7.13509,4.49389.00015Z" 
    transform="translate(-266.62966 -85.37299)" 
    fill={theme.avatarShoes}/>
    
    <polygon 
    points="333.673 681.391 320.54 691.771 274.246 646.056 293.63 630.733 333.673 681.391" 
    fill={theme.skinColor}/>
    
    <path 
    d="M611.5962,780.09759l-40.38567,31.92512-.40376-.51072a20.03864,20.03864,0,0,1,3.29221-28.14525l.001-.00077,2.95242-11.42819,18.18836-5.28364,3.52539-2.78687Z" 
    transform="translate(-266.62966 -85.37299)" 
    fill={theme.avatarShoes}/>
    
    <polygon 
    points="216.918 408.274 215.896 427.117 220.867 433.122 220.297 451.969 225.12 464.305 228.512 503.551 276.018 667.307 318.827 653.576 272.08 500.926 328.324 499.521 330.227 413.447 320.26 394.179 216.918 408.274" 
    fill={theme.imageClothes}/>
    
    <polygon 
    points="322.915 486.545 328.324 499.521 307.409 682.241 278.064 684.384 265.392 480.109 322.915 486.545" 
    fill={theme.imageClothes}/>
    
    <polygon 
    points="294.155 217.278 284.519 203.94 253.405 205.187 252.808 215.457 232.139 410.681 252.437 410.226 277.634 416.109 326.097 399.147 294.155 217.278" fill="#6c63ff"/>
    
    <path 
    d="M506.75037,499.66408H459.03254l12.4494-87.87335,5.04777-35.61819s-3.57314-25.45641-5.303-38.75653a7.52905,7.52905,0,0,1,.709-4.14033,13.65555,13.65555,0,0,1,2.19306-3.30849c5.804-6.6926,18.85837-12.49664,27.04448-15.67277,4.216-1.62586,7.14632-2.56174,7.14632-2.56174L519.436,300.83362C535.39238,320.76963,506.75037,499.66408,506.75037,499.66408Z" 
    transform="translate(-266.62966 -85.37299)" 
    fill={theme.imageClothes}/>
    
    
    <path 
    d="M554.74085,295.39344l15.138,8.67511s44.832,7.3637,49.19173,24.20114l-16.50262,49.81473v104.693L562.56158,498.8201S528.05243,329.68177,554.74085,295.39344Z" 
    transform="translate(-266.62966 -85.37299)" 
    fill={theme.imageClothes}/>
    
    
    <polyline points="272.788 475.879 265.392 561.548 277.985 661.252" opacity="0.4"/>
    
    <path 
    d="M360.84924,401.65842a10.38673,10.38673,0,0,1,15.4297,3.94836l36.67719-4.14793L404.263,418.55666l-33.49654,1.31772a10.443,10.443,0,0,1-9.91715-18.21589Z" 
    transform="translate(-266.62966 -85.37299)" 
    fill={theme.skinColor}/>
    
    <polygon 
    points="248.158 241.42 258.654 247.69 199.871 330.501 128.122 336.597 125.265 316.112 178.129 300.252 204.259 249.604 248.158 241.42" 
    fill={theme.imageClothes}/>
    
    <path d="M728.72485,394.65842a10.38673,10.38673,0,0,0-15.4297,3.94836L676.618,394.45885l8.69318,17.09781,33.49654,1.31772a10.443,10.443,0,0,0,9.91714-18.21589Z" 
    transform="translate(-266.62966 -85.37299)" 
    fill={theme.skinColor}/>
    
    
    <polygon 
    points="308.156 234.42 297.661 240.69 356.444 323.501 428.193 329.597 431.049 309.112 378.186 293.252 352.056 242.604 308.156 234.42" 
    fill={theme.imageClothes}/>
    
    <path d="M431.871,300.30715v-1.09442H413.52373V273.68148H412.4321v25.53125H391.4049V279.496q-.55143.43355-1.08883.89238v18.82431H376.428c-.15436.362-.30308.7268-.44339,1.09442h14.3315v21.45376H372.52165c.01684.36762.0421.72962.073,1.09444h17.72145v17.1153H377.03972c.16277.36761.33394.72961.51355,1.08882h12.7628v14.38762h1.08883V341.05947h21.0272v14.38762h1.09163V341.05947H431.871v-1.08882H413.52373a17.11187,17.11187,0,0,1,17.11528-17.1153h1.232v-1.09444H413.52373V300.30715Zm-19.43889,39.6635H391.4049v-17.1153h21.0272Zm0-18.20974H391.4049v-.42935a21.02578,21.02578,0,0,1,21.0272-21.02441Z" transform="translate(-266.62966 -85.37299)" fill="#e6e6e6"/>
    
    <path d="M591.27643,148.82872a.585.585,0,0,1-.7633-.31646l-1.51923-3.67118a.58428.58428,0,0,1,1.07976-.44683l1.51923,3.67118A.585.585,0,0,1,591.27643,148.82872Z" transform="translate(-266.62966 -85.37299)" fill="#3f3d56"/>
    
    <path d="M621.92031,121.63212h0L617.38554,110.674a6.86385,6.86385,0,0,0-8.96684-3.71764l0,0-23.21621,9.60751a6.86386,6.86386,0,0,0-3.71765,8.96685v0l24.87808,60.117a6.86385,6.86385,0,0,0,8.96684,3.71765l0,0,23.21624-9.60752a6.86384,6.86384,0,0,0,3.71764-8.96682l0,0-17.11539-41.35873h0a.4329.4329,0,0,0,.23447-.56554l-2.89685-7.00015A.43291.43291,0,0,0,621.92031,121.63212Z" transform="translate(-266.62966 -85.37299)" fill="#3f3d56"/>
    
    <path d="M616.05311,111.27488l24.84233,60.0306a5.12266,5.12266,0,0,1-2.72423,6.67288.2783.2783,0,0,1-.0497.02457l-23.32914,9.65425a5.12861,5.12861,0,0,1-6.69886-2.77735l-24.84233-60.0306a5.12547,5.12547,0,0,1,2.77875-6.69545l2.82922-1.17081a2.43615,2.43615,0,0,0,3.36979,2.23864l13.30075-5.50422a2.37045,2.37045,0,0,0,.77482-.52027,2.42943,2.42943,0,0,0,.02081-3.44213l3.03375-1.25545A5.12469,5.12469,0,0,1,616.05311,111.27488Z" transform="translate(-266.62966 -85.37299)" fill="#6c63ff"/><path d="M588.68479,142.56613a.585.585,0,0,1-.76329-.31647l-1.51924-3.67117a.58428.58428,0,1,1,1.07976-.44683l1.51924,3.67117A.585.585,0,0,1,588.68479,142.56613Z" transform="translate(-266.62966 -85.37299)" fill="#3f3d56"/>
    
    <path d="M585.62772,135.48441a.585.585,0,0,1-.7633-.31646l-1.51923-3.67118a.58428.58428,0,1,1,1.07975-.44683l1.51924,3.67117A.585.585,0,0,1,585.62772,135.48441Z" transform="translate(-266.62966 -85.37299)" fill="#3f3d56"/>
    
    <path d="M594.17965,115.50467a.585.585,0,0,1,.31647-.76329l3.67117-1.51924a.58428.58428,0,0,1,.44684,1.07976l-3.67118,1.51924A.585.585,0,0,1,594.17965,115.50467Z" transform="translate(-266.62966 -85.37299)" fill="#fff"/>
    
    <circle cx="333.81259" cy="27.54004" r="0.58428" fill="#fff"/>
    
    <path d="M727.16164,372.75243c-.07367,0-.14734-.00145-.22174-.00436a5.39058,5.39058,0,0,1-4.33476-2.46533,60.89743,60.89743,0,0,0-12.81991-13.88691c-3.00863-2.40473-6.15264-4.6407-9.19285-6.80336-7.8435-5.577-15.23008-10.82963-19.33039-18.08738-8.93989-15.826,1.58815-37.86924,16.62843-47.75491,16.86977-11.08731,39.05927-12.82644,60.5251-13.05436q3.34633-.03539,6.69666-.0352h0a638.49363,638.49363,0,0,1,115.31057,10.54365c12.64861,2.3336,25.88842,5.19235,36.52788,12.13,10.57631,6.89663,17.00478,17.75712,16.37765,27.66928-.91566,14.47012-16.255,25.28234-30.22282,28.10915-13.31348,2.69216-27.14413.87283-41.78627-1.05393-3.23256-.425-6.57472-.86485-9.87987-1.247-35.92-4.15184-103.398,8.6452-106.25272,9.19213l-2.83226.5422L731.365,370.68776A5.27374,5.27374,0,0,1,727.16164,372.75243Z" transform="translate(-266.62966 -85.37299)" fill="#6c63ff"/>
    
    
    <path d="M887.11731,302.16215H735.2958a3.3033,3.3033,0,0,1,0-6.6066H887.11731a3.3033,3.3033,0,0,1,0,6.6066Z" transform="translate(-266.62966 -85.37299)" fill="#fff"/><path d="M887.11731,322.43821H735.2958a3.30331,3.30331,0,0,1-.00689-6.60661h151.8284a3.30331,3.30331,0,0,1,0,6.60661Z" transform="translate(-266.62966 -85.37299)" fill="#fff"/>
    
    <path d="M752.98834,342.71379H735.2958a3.30331,3.30331,0,0,1,0-6.60661h17.69254a3.30331,3.30331,0,0,1,0,6.60661Z" transform="translate(-266.62966 -85.37299)" fill="#fff"/>
    
    <circle cx="462.40689" cy="294.35216" r="16.05687" fill="#fff"/><path d="M729.03656,395.97811a16.25289,16.25289,0,1,1,16.25289-16.253A16.27142,16.27142,0,0,1,729.03656,395.97811Z" transform="translate(-266.62966 -85.37299)" fill="#3f3d56"/>
    
    <path d="M727.83438,386.1424c-.03423,0-.06847-.00111-.1027-.00278a2.09978,2.09978,0,0,1-1.55654-.80875l-2.68338-3.44982a2.10062,2.10062,0,0,1,.3682-2.94832l.09657-.07514a2.1004,2.1004,0,0,1,2.94888.36847,1.53224,1.53224,0,0,0,2.32047.113l5.44831-5.75193a2.10375,2.10375,0,0,1,2.9703-.08071l.08934.08516a2.10038,2.10038,0,0,1,.08015,2.97l-8.45507,8.92456A2.09844,2.09844,0,0,1,727.83438,386.1424Z" transform="translate(-266.62966 -85.37299)" fill="#fff"/>
    
    <path d="M429.22188,172.166l-9.82351-59.0756a4.577,4.577,0,0,1,3.76-5.25986l44.6152-7.41893a4.577,4.577,0,0,1,5.25983,3.76l9.8235,59.07561a4.577,4.577,0,0,1-3.75994,5.25984l-44.6152,7.41893A4.577,4.577,0,0,1,429.22188,172.166Z" transform="translate(-266.62966 -85.37299)" fill="#3f3d56"/>
    
    <path d="M429.677,153.75359l-6.31544-37.97916a4.28642,4.28642,0,0,1,3.52132-4.926l38.343-6.376a4.28661,4.28661,0,0,1,4.92622,3.5213l8.74159,52.56925a4.28661,4.28661,0,0,1-3.52154,4.926l-23.7527,3.94977A19.09378,19.09378,0,0,1,429.677,153.75359Z" transform="translate(-266.62966 -85.37299)" fill="#fff"/>
    
    <path d="M431.33193,125.339a4.18859,4.18859,0,0,1,3.441-4.81367l26.59846-4.423a4.184,4.184,0,1,1,1.37265,8.2547L436.1456,128.78A4.1886,4.1886,0,0,1,431.33193,125.339Z" transform="translate(-266.62966 -85.37299)" fill="#6c63ff"/>
    
    <path d="M433.46716,138.17959a4.1886,4.1886,0,0,1,3.441-4.81367l26.59847-4.423a4.184,4.184,0,0,1,1.37265,8.2547l-26.59847,4.423A4.1886,4.1886,0,0,1,433.46716,138.17959Z" transform="translate(-266.62966 -85.37299)" fill="#6c63ff"/>
    
    <path d="M435.60239,151.02023a4.1886,4.1886,0,0,1,3.441-4.81367l26.59847-4.423a4.184,4.184,0,0,1,1.37265,8.2547l-26.59847,4.423A4.1886,4.1886,0,0,1,435.60239,151.02023Z" transform="translate(-266.62966 -85.37299)" fill="#6c63ff"/>
    
    <path d="M662.32646,772.97087l.70715-15.90017a51.35277,51.35277,0,0,1,23.95261-6.06333c-11.504,9.40539-10.06637,27.53575-17.86583,40.18378a30.8888,30.8888,0,0,1-22.65105,14.27412l-9.627,5.89429a51.75978,51.75978,0,0,1,10.90965-41.9429,49.99707,49.99707,0,0,1,9.18851-8.53763C659.2462,766.95864,662.32646,772.97087,662.32646,772.97087Z" transform="translate(-266.62966 -85.37299)" fill="#f2f2f2"/>
    
    
    <path d="M789.969,813.07379a1.54861,1.54861,0,0,1-1.5533,1.55322H370.36555a1.55322,1.55322,0,1,1,0-3.10644H788.41572A1.54866,1.54866,0,0,1,789.969,813.07379Z" transform="translate(-266.62966 -85.37299)" fill="#ccc"/>
    
    <path d="M371.373,393.65688c-7.41429-.14769-47.23975-1.29075-53.77054-1.00965-.36943-1.2933-12.74985,14.57255-13.05093,14.67089a1.80834,1.80834,0,0,0,.60481,2.90759c.72777.44213,64.93166-.74561,65.70956-.59064a1.7859,1.7859,0,0,0,1.59378-1.03833l.42914-.96116c.04458-1.64431.58291-10.048.09965-11.48483A1.77814,1.77814,0,0,0,371.373,393.65688Z" transform="translate(-266.62966 -85.37299)" fill="#3f3d56"/>
    
    <path d="M372.88872,407.63407c-.37681.90548-.85882,2.04315-2.02291,2.00137l-65.03159.72981a1.7939,1.7939,0,0,1-1.56527-2.63308Z" transform="translate(-266.62966 -85.37299)" opacity="0.17" />
    
    <path d="M317.90631,392.45252c-.7-1.21052-18.08617-87.2383-19.53727-87.95769l-11.87032-14.52105-1.06094-.79779c-1.47535-.81046-5.01435-.26247-4.69564,1.99087h0c2.7887,13.37007,20.7256,89.51848,21.11867,101.39039L304.02,407.68153a1.86252,1.86252,0,0,0,2.29966,1.52983,3.58266,3.58266,0,0,0,2.87739-2.07912C309.35886,406.59851,318.7191,392.76241,317.90631,392.45252Z" transform="translate(-266.62966 -85.37299)" fill="#3f3d56"/>
    
    <path d="M286.49585,289.9744l22.70338,117.16092c-.75925,1.92173-4.312,3.26805-5.13947.74718-.01447-.00347-23.29115-116.64351-23.31482-116.71324C280.49694,288.36213,285.1354,288.27309,286.49585,289.9744Z" transform="translate(-266.62966 -85.37299)" opacity="0.17" />
    
    <polygon points="20.55 209.392 32.081 225.464 45.023 286.929 39.006 292.177 20.55 209.392" fill="#6c63ff"/><path d="M363.93011,401.94452l3.19.13a12.53882,12.53882,0,0,1,.46-2.76c.48-2.28.91-4.99-1.08-4.98-1.35-.13-1.81,1.58-2.05,3.44A26.3201,26.3201,0,0,1,363.93011,401.94452Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/>
    
    <path d="M345.63012,397.65454l2.15.08,1.04.04c-.02-1.14.51-3.49-.78-4.07a1.60015,1.60015,0,0,0-.65-.13c-1.21-.07-1.57.85-1.68,1.91-.07.64-.04,1.32-.06,1.85C345.65008,397.44452,345.64013,397.55451,345.63012,397.65454Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/>
    
    
    <path d="M339.26012,397.39453l2.16.09,1.03.04c-.02-1.31.67-4.17005-1.43006-4.21-1.94-.11-1.69,2.36-1.74,3.76C339.28008,397.18451,339.27007,397.2945,339.26012,397.39453Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/>
    
    <path d="M332.89013,397.14453l2.15.08,1.04.04c-.02-1.3.67-4.16-1.43-4.2-1.94-.12-1.69,2.35-1.74,3.76C332.91009,396.93451,332.90008,397.0445,332.89013,397.14453Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/>
    
    <path d="M326.52007,396.88452l2.16.09,1.03.04c-.02-1.31.67-4.16-1.43-4.21-1.94-.11-1.69,2.36-1.74,3.76C326.54009,396.6745,326.53008,396.78455,326.52007,396.88452Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/>
    
    <path d="M320.15008,396.63452l2.15.08,1.04.04c-.02-1.2.56-3.71-.98-4.14h-.01a1.46425,1.46425,0,0,0-.44-.06,1.5059,1.5059,0,0,0-.54.05h-.01c-1.34.35-1.15,2.45-1.19,3.71C320.1701,396.4245,320.16009,396.53455,320.15008,396.63452Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/>
    
    <path d="M357.50011,395.95453l3.19.13c0-.12,0-.25.01-.4a13.33143,13.33143,0,0,0,.04-2.29c-.11-.84-.47-1.5-1.48-1.52-1.03-.06-1.44.6-1.6,1.45a3.11452,3.11452,0,0,0-.08.44v.01C357.50011,394.55451,357.5501,395.39453,357.50011,395.95453Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/>
    
    <path d="M351.13012,395.69452l3.19.13a23.28057,23.28057,0,0,0,.06-2.58c-.09-.88-.44-1.6-1.49-1.62-1.06006-.07-1.47.65-1.63,1.54C351.1101,394.02454,351.19012,395.05451,351.13012,395.69452Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/>
    
    <path d="M344.76012,395.44452l.95.04,2.24.09c-.01-.48.07995-1.18.09-1.87a4.23306,4.23306,0,0,0-.02-.62c-.07995-.93-.41-1.7-1.5-1.72-1.11-.06-1.5.71-1.63995,1.65C344.7401,393.85449,344.82012,394.82453,344.76012,395.44452Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/>
    
    <path d="M357.56011,401.69452l3.19.12c-.01-1.3.67-4.16-1.43-4.2C357.22008,397.4845,357.68011,400.38452,357.56011,401.69452Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/>
    
    <path d="M351.19012,401.43451l3.19.13c-.01-1.31.67-4.17-1.43-4.21C350.85009,397.2345,351.31011,400.13452,351.19012,401.43451Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/>
    
    <path d="M344.82012,401.18451l3.19.12c-.01-.93.34-2.65-.23-3.57a1.31957,1.31957,0,0,0-1.2-.63,1.40551,1.40551,0,0,0-.93.23005C344.63012,398.00452,344.9201,400.12451,344.82012,401.18451Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/>
    
    <path d="M338.45013,400.9245l3.19.13c-.01-.93.32995-2.64-.22-3.57a1.3234,1.3234,0,0,0-1.21-.63995,1.3893,1.3893,0,0,0-.93.23C338.26012,397.76453,338.5501,399.87451,338.45013,400.9245Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/>
    
    <path d="M332.08007,400.6745l3.19.12c-.01-.93.34-2.65-.23-3.57a1.3193,1.3193,0,0,0-1.2-.63,1.4051,1.4051,0,0,0-.93.23C331.89013,397.49451,332.18011,399.6145,332.08007,400.6745Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/
    
    ><path d="M325.71008,400.41455l3.19.13c-.01-.93.33-2.64-.22-3.57a1.31241,1.31241,0,0,0-1.21-.64,1.38919,1.38919,0,0,0-.93.23C325.52007,397.25452,325.81011,399.3645,325.71008,400.41455Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/>
    
    <path d="M319.34008,400.16455l3.19.12c-.01-.93006.33-2.64-.23-3.57a1.30821,1.30821,0,0,0-1.2-.63,1.40491,1.40491,0,0,0-.93.23C319.15008,396.99451,319.44012,399.10449,319.34008,400.16455Z" transform="translate(-266.62966 -85.37299)" fill="#2f2e41"/></svg>
  );
}
