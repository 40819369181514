export const education = {

  title: "Education",
  subtitle: "Qualifications and Certificates",

  description: `🤓 Currently a Grad pursuing Software Engineering @ UMD `,
  description1: `📜 Completed my Bachelors in Computer Engineering in 2020 from Mumbai University`,

  degrees: [
    {
      title: "University Of Maryland, College Park",
      subtitle: "Masters Of Engineering in Software Engineering",
      logo_path: "UMCP.svg",
      alt_name: "UMCP",
      duration: "2023 - 2025",
      descriptions: [
        "⚡ Software Engineering graduate student.",
        "⚡ Relevant Courses :  Software Engineering, AI and Deep Learning , Cloud Computing (GCP, AWS), Containerization (Docker and Kubernetes)",


      ],
      website_link: "https://umd.edu/",
    },
    {
      title: "Vidyalankar Institute Of Technology, Mumbai University",
      subtitle: "Bachelor of Engineering in Computer Science",
      logo_path: "VIT.png",
      alt_name: "VIT",
      duration: "2016 - 2020",
      descriptions: [
        "⚡ Relevant Courses : Structured Programming Languages(C), Object Oriented Programming(Java), Operating System, Database Management, Computer Networks, Data Structures And Algorithms, Web Designing, Software Engineering, Machine Learning, Data Warehousing and Mining, Big Data and Analytics, Artificial Intelligence, NLP, Distributed Computing, Cloud Computing.",
        "⚡ Created a AR mobile application in Unity 3D as my final year project to display 3D models of food items on scanning the items in the menu which could be customized based on the user needs aimed to enhance the user experience at restaurants"

      ],
      website_link: "https://vit.edu.in/",
    }
  ],

  competitiveProgrammingPlatforms: [
    {
      platform: "LeetCode",
      link: "https://leetcode.com/tarang_98/",
      iconifyIconId: "simple-icons:leetcode",
      style: {
        color: "#f3b548",
      },
    },
    {
      platform: "Hackerrank",
      link: "https://www.hackerrank.com/tarang98?hr_r=1",
      iconifyIconId: "simple-icons:hackerrank",
      style: {
        color: "#49b463",
      },
    },
    {
      platform: "Codechef",
      link: "https://www.codechef.com/users/tarang_98",
      iconifyIconId: "simple-icons:codechef",
      style: {
        color: "#9ea39f",
      },
    },
    {
      platform: "GFG",
      link: "https://auth.geeksforgeeks.org/user/tarangnair98/practice/",
      iconifyIconId: "simple-icons:geeksforgeeks",
      style: {
        color: "#157d2f",
      },
    },


  ],


  certifications: [
    {
      title: "Introduction to TensorFlow for Artificial Intelligence, Machine Learning, and Deep Learning",
      subtitle: "DeepLearning.AI",
      logo_path: "deepLearningAI.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/ZS3BTZBCFWJG",
      alt_name: "",
      color_code: "#eef1ee",
    },
    {
      title: "Convolutional Neural Networks in TensorFlow",
      subtitle: "DeepLearning.AI",
      logo_path: "deepLearningAI.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/TBPNWJND3MHG",
      alt_name: "",
      color_code: "#eef1ee",
    },
    {
      title: "Natural Language Processing in TensorFlow",
      subtitle: "DeepLearning.AI",
      logo_path: "deepLearningAI.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/Q6DZXW3YM33N",
      alt_name: "",
      color_code: "#eef1ee",
    },


  ],



}


